// src/stores/RecordingStore.ts
import { makeAutoObservable, runInAction, action } from "mobx";
import { RecordingManager } from "../models/RecordingManager";
import { IAuthor, IRecording, IRecordingWithAuthor, ITimingData } from "../types";

class RecordingStoreImpl {
  recordingsByPinKey: Map<string, IRecordingWithAuthor[]> = new Map();
  recordings: Map<string, IRecordingWithAuthor> = new Map();
  loading: boolean = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  getRecording(key: string) {
    return this.recordings.get(key);
  }

  async fetchRecordingsForPin(pinKey: string) {
    this.setLoading(true);
    try {
      const recordingsForPin = await RecordingManager.getRecordingsForPin(pinKey);
      runInAction(() => {
        this.recordingsByPinKey.set(pinKey, recordingsForPin);
        recordingsForPin.forEach(recording => {
          this.recordings.set(recording.key, recording);
        });
        this.setLoading(false);
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : String(error);
        this.setLoading(false);
      });
    }
    return this.recordingsByPinKey.get(pinKey) || [];
  }

  async fetchRecording(key: string) {
    this.setLoading(true);
    try {
      const recording = await RecordingManager.getRecordingWithAuthor(key);
      runInAction(() => {
        if (recording) {
          this.recordings.set(key, recording);
        }
        this.setLoading(false);
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : String(error);
        this.setLoading(false);
      });
    }
  }

  async updateRecording(key: string, updatedData: Partial<IRecordingWithAuthor>) {
    try {
      await RecordingManager.updateRecording(key, updatedData);
      runInAction(() => {
        const existingRecording = this.recordings.get(key);
        if (existingRecording) {
          this.recordings.set(key, { ...existingRecording, ...updatedData });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : String(error);
      });
    }
  }

  updateRecordingTimingData(key: string, updatedTimingData: ITimingData[]) {
    runInAction(() => {
      const existingRecording = this.recordings.get(key);
      if (existingRecording) {
        existingRecording.timingData = updatedTimingData;
        this.recordings.set(key, existingRecording);
      }
    });
  }

  getRecordingKeysForProject(project_id: string): string[] {
    return Array.from(this.recordings.values())
      .filter(recording => recording.project === project_id)
      .map(recording => recording.key);
  }

  addRecording(recording: IRecordingWithAuthor) {
    runInAction(() => {
      this.recordings.set(recording.key, recording);
    });
  }

  setRecordings(newRecordings: IRecordingWithAuthor[]) {
    runInAction(() => {
      this.recordings = new Map();
      newRecordings.forEach(recording => {
        this.recordings.set(recording.key, recording);
      });
    });
  }

  setLoading(loading: boolean) {
    runInAction(() => {
      this.loading = loading;
    });
  }

  setError(error: string | null) {
    runInAction(() => {
      this.error = error;
    });
  }

  removeRecording(key: string) {
    runInAction(() => {
      this.recordings.delete(key);
    });
  }

  updateRecordingAuthor(recordingKey: string, authorData: Partial<IAuthor>) {
    runInAction(() => {
      const recording = this.recordings.get(recordingKey);
      if (recording && recording.author) {
        recording.author = {
          ...recording.author,
          ...authorData,
          tags: {
            authorTags: authorData.tags?.authorTags || recording.author.tags?.authorTags || []
          }
        };
        this.recordings.set(recordingKey, recording);
      }
    });
  }

  async fetchRecordingsForTransitProject(project_id: string) {
    this.setLoading(true);
    try {
      const recordings = await RecordingManager.fetchRecordingsForTransitProject(project_id);
      // Sort the recordings by collectionOrder before setting them
      const sortedRecordings = recordings.sort((a, b) => (a.collectionOrder || 0) - (b.collectionOrder || 0));
      runInAction(() => {
        this.setTransitRecordings(project_id, sortedRecordings);
        this.setLoading(false);
      });
      return this.getTransitRecordings(project_id);
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : String(error);
        this.setLoading(false);
      });
      return [];
    }
  }

  setTransitRecordings(project_id: string, recordings: IRecordingWithAuthor[]) {
    runInAction(() => {
      const sortedRecordings = recordings.sort((a, b) => (a.collectionOrder || 0) - (b.collectionOrder || 0));
      this.recordings.clear();
      sortedRecordings.forEach(recording => {
        this.recordings.set(recording.key, recording);
      });
    });
  }

  getTransitRecordings(project_id: string): IRecordingWithAuthor[] {
    return Array.from(this.recordings.values())
      .filter(recording => recording.project === project_id)
      .sort((a, b) => (a.collectionOrder || 0) - (b.collectionOrder || 0));
  }

  async fetchRecordingsForAuthor(authorKey: string) {
    this.setLoading(true);
    try {
      const recordingsForAuthor = await RecordingManager.getRecordingsByAuthorKey(authorKey);
      runInAction(() => {
        this.recordings.clear();
        recordingsForAuthor.forEach(recording => {
          this.recordings.set(recording.key, recording);
        });
        this.setLoading(false);
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : String(error);
        this.setLoading(false);
      });
    }
  }
}

const RecordingStore = new RecordingStoreImpl();
RecordingStore.addRecording = action(RecordingStore.addRecording);
RecordingStore.setRecordings = action(RecordingStore.setRecordings);

export default RecordingStore;
