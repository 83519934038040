import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { TextField, InputAdornment } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import { auth } from '../firebase/firebase';
import UserManager from '../models/UserManager';
import AuthorManager from '../models/AuthorManager';
import { v4 as uuidv4 } from 'uuid';
import './FormLogin.css';
import { colors } from '../theme/colors';

interface FormAuthorSignUpProps {
  onAccountExists: () => void;
  onSignUpSuccess?: () => void;
  initialAuthorName: string;
  onToggleForm: () => void;
  isModal?: boolean;
}

const FormAuthorSignUp: React.FC<FormAuthorSignUpProps> = ({
  onAccountExists,
  onSignUpSuccess,
  initialAuthorName,
  onToggleForm,
  isModal = false,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState(initialAuthorName);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    console.log('Sign-up process started');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      console.log('Attempting to create user with email:', email);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log('User created successfully:', user.uid);

      const authorKey = uuidv4();

      // Update user document creation in Users collection
      console.log('Creating user document with data:', { userKey: user.uid, email, name });
      await UserManager.createUser({
        userKey: user.uid,
        userProfile: {
          userName: name,
          profileImage: '',
          bio: '',
          expoPushToken: '',
          restrictAutoAdd: false,
        },
        isAuthor: true,
        authorKey,
      });
      console.log('User document created successfully');

      if (isModal) {
        // For AuthModal, create author and call onSignUpSuccess
        console.log('Creating new author document');
        const newAuthorData = {
          name,
          userKey: user.uid,
          tags: { authorTags: [] },
          isClaimed: true,
        };
        await AuthorManager.createAuthor(authorKey, newAuthorData);
        console.log('Author document created successfully');

        if (onSignUpSuccess) {
          console.log('Calling onSignUpSuccess callback');
          onSignUpSuccess();
        }
      } else {
        // For AuthorLoginScreen, check for potential authors and navigate
        console.log('Checking for potential authors with name:', name);
        const potentialAuthors = await AuthorManager.getAuthorsByName(name);
        console.log('Potential authors found:', potentialAuthors.length);

        if (potentialAuthors.length > 0) {
          console.log('Navigating to author claim process');
          navigate('/author-claim-process', { state: { name, userKey: user.uid, authorKey } });
        } else {
          console.log('Creating new author document');
          const newAuthorData = {
            name,
            userKey: user.uid,
            tags: { authorTags: [] },
            isClaimed: true,
          };
          await AuthorManager.createAuthor(authorKey, newAuthorData);
          console.log('Author document created successfully');
          console.log('Navigating to author dashboard');
          navigate('/author-dashboard');
        }

        if (onSignUpSuccess) {
          console.log('Calling onSignUpSuccess callback');
          onSignUpSuccess();
        }
      }

    } catch (error: any) {
      console.error('Error during sign-up process:', error);
      if (error.code === 'auth/email-already-in-use') {
        console.log('Email already in use, calling onAccountExists');
        onAccountExists();
      } else {
        setError('Failed to create account. Please try again.');
        console.error('Error creating author account:', error);
      }
    }
  };

  return (
    <form onSubmit={handleSignUp} className="login-form">
      <h2 className="signUp-text" style={{ color: colors.palette.neutral100 }}>Author Sign Up</h2>
      <TextField
        sx={{
          backgroundColor: "var(--color-lightsteelblue)",
          alignSelf: "stretch",
          borderRadius: "var(--br-8xs)",
          padding: "0 var(--padding-2xl)",
          boxSizing: "border-box",
          '& .MuiOutlinedInput-root': {
            fontSize: "var(--font-size-base)",
            fontFamily: "var(--font-manrope)",
            border: 0,
            fontWeight: 500,
            height: "50px",
            display: "flex",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', 
          },
        }}
        placeholder="Name"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        sx={{
          backgroundColor: "var(--color-lightsteelblue)",
          alignSelf: "stretch",
          borderRadius: "var(--br-8xs)",
          padding: "0 var(--padding-2xl)",
          boxSizing: "border-box",
          '& .MuiOutlinedInput-root': {
            fontSize: "var(--font-size-base)",
            fontFamily: "var(--font-manrope)",
            border: 0,
            fontWeight: 500,
            height: "50px",
            display: "flex",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', 
          },
        }}
        placeholder="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        sx={{
          backgroundColor: "var(--color-lightsteelblue)",
          alignSelf: "stretch",
          borderRadius: "var(--br-8xs)",
          padding: "0 var(--padding-2xl)",
          boxSizing: "border-box",
          '& .MuiOutlinedInput-root': {
            fontSize: "var(--font-size-base)",
            fontFamily: "var(--font-manrope)",
            border: 0,
            fontWeight: 500,
            height: "50px",
            display: "flex",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', 
          },
        }}
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        sx={{
          backgroundColor: "var(--color-lightsteelblue)",
          alignSelf: "stretch",
          borderRadius: "var(--br-8xs)",
          padding: "0 var(--padding-2xl)",
          boxSizing: "border-box",
          '& .MuiOutlinedInput-root': {
            fontSize: "var(--font-size-base)",
            fontFamily: "var(--font-manrope)",
            border: 0,
            fontWeight: 500,
            height: "50px",
            display: "flex",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', 
          },
        }}
        placeholder="Confirm Password"
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}
      <button type="submit" className="login-button">
        <span className="login-text">Sign Up as Author</span>
      </button>
      <p className="login-link" style={{ color: colors.palette.neutral100, marginTop: '10px', textAlign: 'center' }}>
        Already have an account? <a onClick={onToggleForm} style={{ color: colors.palette.neutral100, textDecoration: 'underline', cursor: 'pointer' }}>Login</a>
      </p>
    </form>
  );
};

export default FormAuthorSignUp;