// transcriptionService.ts

import { getAuth } from 'firebase/auth';

interface TranscriptionParams {
    fileName: string;
    recordingId: string;
    transcriptionText?: string;
    timingData?: any; // Add this line
  }
  
  const TRANSCRIPTION_URL = 'https://europe-west2-overhear-2.cloudfunctions.net/speechToText/speech-to-text';

  const fetchWithRetry = async (url: string, options: RequestInit, retries: number = 3, delay: number = 1000): Promise<Response> => {
    for (let i = 0; i < retries; i++) {
        const response = await fetch(url, options);
        if (response.ok) {
            return response;
        }
        if (i < retries - 1) {
            await new Promise(res => setTimeout(res, delay));
        }
    }
    throw new Error(`Failed to fetch ${url} after ${retries} retries`);
};

export const initiateTranscription = async ({ fileName, recordingId, transcriptionText, timingData }: TranscriptionParams): Promise<any> => {
    try {
        console.log('Initiating transcription for:', { fileName, recordingId });
        if (!fileName) {
          throw new Error('Recording path is undefined');
        }
        
        // Get the current user's ID token
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          throw new Error('No authenticated user found');
        }
        const idToken = await user.getIdToken();

        const transcriptionResponse = await fetchWithRetry(TRANSCRIPTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify({ 
                fileName, 
                recordingId, 
                transcriptionText,
                timingData
            }),
        });

        if (!transcriptionResponse.ok) {
            const errorText = await transcriptionResponse.text();
            console.error('Transcription service error:', transcriptionResponse.status, errorText);
            throw new Error(`HTTP error! status: ${transcriptionResponse.status}, message: ${errorText}`);
        }

        const data = await transcriptionResponse.json();
        console.log('Transcription initiated successfully:', data);
        return data;
    } catch (error) {
        console.error('Error initiating transcription:', error);
        throw error;
    }
  };
