//RecordingManager.ts
// Importing necessary libraries and modules
import { db } from '../firebase/firebase';
import { collection, getDocs, getDoc, doc, updateDoc, where, query, addDoc, deleteDoc, setDoc, orderBy, writeBatch } from 'firebase/firestore';
import { IRecording } from '../types/Recording';
import { EventEmitter } from 'eventemitter3';
import { IRecordingWithAuthor, ITimingData } from '../types';
import { AuthorManager } from './AuthorManager';
import AlbumManager from './AlbumManager';
import { v4 as uuidv4 } from 'uuid';

// Creating an event emitter instance
export const eventEmitter = new EventEmitter();

// Method to fetch all recordings from Firestore
export const RecordingManager = {
  // Method to fetch all recordings from Firestore
  observeRecordings: async (): Promise<IRecording[]> => {
    try {
      const querySnapshot = await getDocs(collection(db, 'Recordings'));
      const recordings: IRecording[] = [];
      querySnapshot.forEach((docSnapshot) => {
        let recording = { key: docSnapshot.id, ...docSnapshot.data() } as IRecording;
        recordings.push(recording);
      });
      return recordings;
    } catch (error) {
      console.log("Error getting recordings:", error);
      return [];
    }
  },
  // Method to fetch recordings associated with a specific pin key
  getRecordingsForPin: async (pinKey: string): Promise<IRecordingWithAuthor[]> => {
    try {
      const recordingsRef = collection(db, 'Recordings');
      const q = query(recordingsRef, where('pinKey', '==', pinKey));
      const querySnapshot = await getDocs(q);
      const recordings: IRecordingWithAuthor[] = [];

      for (const docSnapshot of querySnapshot.docs) {
        const recording = { key: docSnapshot.id, ...docSnapshot.data() } as IRecording;
        // Optionally, if you need the author details with each recording:
        if (recording.ownership?.recordingAuthor) {
          const author = await AuthorManager.getAuthor(recording.ownership.recordingAuthor);
          recordings.push({ ...recording, author } as IRecordingWithAuthor);
        } else {
          recordings.push({ ...recording, author: null } as IRecordingWithAuthor);
        }
      }

      return recordings;
    } catch (error) {
      console.error("Error fetching recordings for pin:", error);
      return [];
    }
  },
  // Method to fetch a specific recording from Firestore
  getRecording: async (key: string): Promise<IRecording | null> => {
    try {
      const docSnapshot = await getDoc(doc(db, 'Recordings', key));
      if (docSnapshot.exists()) {
        let recording = { key: docSnapshot.id, ...docSnapshot.data() } as IRecording;
        // use getAudioPlayerURL to set url regarless of recordingPath or ausioURL being populated in firebase

        return recording;
      } else {
        console.log(`No document found with key: ${key}`);
        return null;
      }
    } catch (error) {
      console.error("Error getting recording:", error);
      return null;
    }
  },

  // used in OverhearScreen to collect recording using a pins albumKey
  fetchRecordingByAlbumKey: async (albumKey: string): Promise<IRecording | null> => {
    try {
      const querySnapshot = await getDocs(collection(db, 'Recordings'));
      let recording: IRecording | null = null;
      querySnapshot.forEach((docSnapshot) => {
        const currentRecording = { key: docSnapshot.id, ...docSnapshot.data() } as IRecording;
        if (currentRecording.albumKey === albumKey) {
          // use getAudioPlayerURL to set url regardless of recordingPath or audioURL being populated in firebase
          recording = currentRecording;
        }
      });
      return recording;
    } catch (error) {
      console.log(`Error fetching recording with albumKey: ${albumKey}:`, error);
      return null;
    }
  },

  fetchRecordingsByAlbumKey: async (albumKey: string): Promise<IRecording[]> => {
    try {
      const recordingsRef = collection(db, 'Recordings');
      const q = query(recordingsRef, where('albumKey', '==', albumKey), orderBy('sequenceNumber'));
      const querySnapshot = await getDocs(q);
      const recordings: IRecording[] = [];
      querySnapshot.forEach((docSnapshot) => {
        recordings.push({ key: docSnapshot.id, ...docSnapshot.data() } as IRecording);
      });
      return recordings;
    } catch (error) {
      console.error(`Error fetching recordings with albumKey: ${albumKey}:`, error);
      return [];
    }
  },

  createRecording: async (newRecordingData: IRecordingWithAuthor): Promise<string | null> => {
    try {
      // Use the provided key as the document ID
      const recordingDocRef = doc(db, 'Recordings', newRecordingData.key);
      await setDoc(recordingDocRef, newRecordingData);
      console.log("Recording created with ID: ", newRecordingData.key);
      return newRecordingData.key; // Return only the key
    } catch (error) {
      console.error("Error creating new recording:", error);
      return null;
    }
  },

  updateRecording: async (key: string, updatedData: Partial<IRecording>): Promise<void> => {
    try {
      const recordingDocRef = doc(db, 'Recordings', key);
      await updateDoc(recordingDocRef, updatedData);
    } catch (error) {
      // If error is an instance of Error, use its message property; otherwise, convert to string
      const errorMessage = error instanceof Error ? error.message : String(error);
      console.error("Error updating recording:", errorMessage);
      throw new Error(errorMessage);
    }
  },

  // Method to update the timingData of a specific recording
  updateTimingData: async (recordingId: string, updatedTimingData: ITimingData[]): Promise<void> => {
    try {
      const recordingDocRef = doc(db, 'Recordings', recordingId);
      await updateDoc(recordingDocRef, { timingData: updatedTimingData });
      console.log(`Timing data for recording ${recordingId} updated successfully.`);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      console.error("Error updating timing data:", errorMessage);
      throw new Error(errorMessage);
    }
  },

  removeRecording: async (key: string): Promise<void> => {
    try {
      const recordingDocRef = doc(db, 'Recordings', key);
      await deleteDoc(recordingDocRef);
      console.log(`Recording with key ${key} deleted successfully.`);
    } catch (error) {
      console.error("Error removing recording:", error);
      throw error;
    }
  },

  getRecordingsByProjectKey: async (project: string): Promise<IRecording[]> => {
    try {
      const recordingsRef = collection(db, 'Recordings');
      const q = query(recordingsRef, where('project', '==', project));
      const querySnapshot = await getDocs(q);
      const recordings: IRecording[] = querySnapshot.docs.map(docSnapshot => {
        return { key: docSnapshot.id, ...docSnapshot.data() } as IRecording;
      });
      return recordings;
    } catch (error) {
      console.error("Error fetching recordings by project key: ", error);
      return [];
    }
  },

  getRecordingWithAuthor: async (key: string): Promise<IRecordingWithAuthor | null> => {
    try {
      const docSnapshot = await getDoc(doc(db, 'Recordings', key));
      if (docSnapshot.exists()) {
        let recording = { key: docSnapshot.id, ...docSnapshot.data() } as IRecording;
        // Check if the recording has an authorKey and fetch the author data
        if (recording.ownership?.recordingAuthor) {
          const author = await AuthorManager.getAuthor(recording.ownership?.recordingAuthor);
          return { ...recording, author } as IRecordingWithAuthor;
        } else {
          return { ...recording, author: null } as IRecordingWithAuthor;
        }
      } else {
        console.log(`No document found with key: ${key}`);
        return null;
      }
    } catch (error) {
      console.error("Error getting recording with author:", error);
      return null;
    }
  },
  getOrphanedRecordingsForProject: async (projectKey: string): Promise<IRecording[]> => {
    try {
      const recordingsRef = collection(db, 'Recordings');
      // Query for recordings where pinKey is not set and the project matches the given projectKey
      const q = query(recordingsRef, where('pinKey', '==', ""), where('project', '==', projectKey));
      const querySnapshot = await getDocs(q);
      const recordings: IRecording[] = [];
      querySnapshot.forEach((docSnapshot) => {
        recordings.push({ key: docSnapshot.id, ...docSnapshot.data() } as IRecording);
      });
      return recordings;
    } catch (error) {
      console.error(`Error fetching orphaned recordings for project ${projectKey}:`, error);
      return [];
    }
  },

  assignRecordingToPin: async (recordingId: string, pinId: string): Promise<void> => {
    const recordingRef = doc(db, 'Recordings', recordingId);
    try {
      const recordingSnap = await getDoc(recordingRef);
      if (!recordingSnap.exists()) {
        throw new Error(`No recording found with ID: ${recordingId}`);
      }
      const recording = recordingSnap.data() as IRecording;

      if (recording.pinKey && recording.pinKey === pinId) {
        console.log(`Recording is already assigned to pin ${pinId}`);
        return;
      }

      await updateDoc(recordingRef, { pinKey: pinId });

      if (!recording.albumKey) {
        const uniqueAlbumId = uuidv4();
        try {
          const newAlbum = await AlbumManager.createAlbum({
            id: uniqueAlbumId,
            name: `Album for Pin ${pinId}`,
            projectKey: recording.project ?? null,
            userKey: recording.ownership?.recordingOwner ?? null,
            albumKey: uniqueAlbumId
          });
          await updateDoc(recordingRef, { albumKey: newAlbum.albumKey });
        } catch (error) {
          console.error("Failed to create new album", error);
          throw error;
        }
      }

      console.log(`Recording ${recordingId} assigned to pin ${pinId} successfully.`);
    } catch (error) {
      console.error(`Error assigning recording to pin:`, error);
      throw error;
    }
  },

  fetchRecordingsForTransitProject: async (project_id: string): Promise<IRecordingWithAuthor[]> => {
    const recordings = await RecordingManager.getRecordingsByProjectKey(project_id);
    const recordingsWithAuthors = await Promise.all(recordings.map(async (recording) => {
      if (recording.ownership?.recordingAuthor) {
        const author = await AuthorManager.getAuthor(recording.ownership.recordingAuthor);
        return { ...recording, author } as IRecordingWithAuthor;
      }
      return recording as IRecordingWithAuthor;
    }));
    return recordingsWithAuthors;
  },

  updateRecordingsOrder: async (project_id: string, orderedRecordings: { key: string; order: number }[]) => {
    const batch = writeBatch(db);
    
    orderedRecordings.forEach(({ key, order }) => {
      const recordingRef = doc(db, 'Recordings', key);
      batch.update(recordingRef, { collectionOrder: order });
    });

    await batch.commit();
  },

  updateRecordingsAuthor: async (oldAuthorKeys: string[], newAuthorKey: string): Promise<void> => {
    try {
      const recordingsRef = collection(db, 'Recordings');
      const q = query(recordingsRef, where('ownership.recordingAuthor', 'in', oldAuthorKeys));
      const querySnapshot = await getDocs(q);

      const batch = writeBatch(db);

      querySnapshot.docs.forEach(doc => {
        batch.update(doc.ref, { 'ownership.recordingAuthor': newAuthorKey });
      });

      await batch.commit();
      console.log(`Updated recordings for authors: ${oldAuthorKeys.join(', ')} to new author: ${newAuthorKey}`);
    } catch (error) {
      console.error("Error updating recordings author:", error);
      throw error;
    }
  },

  getRecordingsByAuthorKey: async (authorKey: string): Promise<IRecording[]> => {
    try {
      const recordingsRef = collection(db, 'Recordings');
      const q = query(recordingsRef, where('ownership.recordingAuthor', '==', authorKey));
      const querySnapshot = await getDocs(q);
      
      return querySnapshot.docs.map(doc => ({
        key: doc.id,
        ...doc.data()
      } as IRecording));
    } catch (error) {
      console.error("Error fetching recordings for author:", error);
      return [];
    }
  }
}
