import { ProjectContext } from '../contexts/ProjectContext';

/**
 * Sends an email notification with form submission details.
 * 
 * This function sends an email to a predefined address with information about
 * a form submission, including the project ID and location description.
 * 
 * @param {string} locationDesc - The description of the location from the submitted form.
 * @param {string} [project_id] - The ID of the project associated with the form submission.
 * @returns {Promise<void>} A promise that resolves when the email is sent successfully, or rejects on failure.
 * @throws {Error} Throws an error if the project_id is undefined or if the email sending fails.
 */
export const sendEmailNotification = async (locationDesc: string, project_id?: string): Promise<void> => {
  if (!project_id) {
    console.error('Cannot send email notification: project_id is undefined');
    return;
  }

  console.log('Sending email with Location Description:', locationDesc);  // Log the location description being sent
  const response = await fetch('https://email-server-dot-overhear-2.uc.r.appspot.com/api/send-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      to: 'hello@theoverhear.app',
      subject: 'FormsView Submitted',
      text: `The form has been submitted with the following details:
      - Project ID: ${project_id}
      - Location Description: ${locationDesc}`  // Use the passed locationDesc
    }),
  });

  if (response.ok) {
    console.log('Email sent successfully');
  } else {
    console.error('Failed to send email');
    throw new Error('Failed to send email notification');
  }
};