import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Grid, Paper, List, ListItem, ListItemText, Button, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import AuthorInformationFields from '../components/AuthorInformationFields';
import { AuthorManager, PinManager } from '../models';
import { auth } from '../firebase/firebase';
import RecordingStore from '../stores/RecordingStore';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { IPin } from '../types';
import { constructUrl } from '../utilities/constructUrl';
import './AuthorDashboard.css';
import { IProject } from '../types/Project';
import { ProjectManager } from '../models'; // Assuming you have a ProjectManager

const AuthorDashboard: React.FC = observer(() => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [authorDetails, setAuthorDetails] = useState({
    authorKey: '',
    authorName: '',
    authorBio: '',
    authorWebsite: '',
    authorTags: [] as string[],
    authorImageUrl: '/assets/icons/user.svg',
  });
  const [authorPins, setAuthorPins] = useState<IPin[]>([]);
  const [projectsMap, setProjectsMap] = useState<Map<string, IProject>>(new Map());
  const mapRef = useRef<L.Map | null>(null);

  useEffect(() => {
    const fetchAuthorDetailsAndRecordings = async () => {
      setIsLoading(true);
      const user = auth.currentUser;
      if (user) {
        const authorData = await AuthorManager.getAuthorByUserKey(user.uid);
        if (authorData) {
          setAuthorDetails({
            authorKey: authorData.authorKey || '',
            authorName: authorData.name || '',
            authorBio: authorData.bio || '',
            authorWebsite: authorData.website || '',
            authorTags: authorData.tags.authorTags || [],
            authorImageUrl: authorData.image || '/assets/icons/user.svg',
          });

          if (authorData.authorKey) {
            await RecordingStore.fetchRecordingsForAuthor(authorData.authorKey);
            const pins = await fetchPinsForAuthor(authorData.authorKey);
            setAuthorPins(pins);

            // Fetch projects for all recordings
            const projectKeys = Array.from(new Set(Array.from(RecordingStore.recordings.values()).map(r => r.project).filter((key): key is string => key !== undefined)));
            const projects = await Promise.all(projectKeys.map(key => ProjectManager.getProject(key)));
            const projectMap = new Map(projects.filter((p): p is IProject => p !== null).map(p => [p.key, p]));
            setProjectsMap(projectMap);
          }
        }
      }
      setIsLoading(false);
    };

    fetchAuthorDetailsAndRecordings();
  }, []);

  useEffect(() => {
    if (isLoading) return; // Don't initialize map while loading

    if (!mapRef.current && document.getElementById('authorMap')) {
      initializeMap();
    } else if (mapRef.current) {
      updateMapPins();
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [authorPins, isLoading]);

  const fetchPinsForAuthor = async (authorKey: string): Promise<IPin[]> => {
    const recordings = Array.from(RecordingStore.recordings.values());
    const pinKeys = recordings.map(recording => recording.pinKey).filter((key): key is string => key !== undefined);
    const pins = await Promise.all(pinKeys.map(pinKey => PinManager.getPin(pinKey)));
    return pins.filter((pin): pin is IPin => pin !== null);
  };

  const initializeMap = () => {
    if (!mapRef.current) {
      mapRef.current = L.map('authorMap').setView([0, 0], 2);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(mapRef.current);

      authorPins.forEach(pin => {
        if (pin.location?.geoPoint) {
          const { lat, long } = pin.location.geoPoint;
          const iconUrl = constructUrl(pin.pinIcon);
          const icon = L.icon({
            iconUrl: iconUrl,
            iconSize: [40, 40],
            iconAnchor: [20, 40]
          });
          L.marker([Number(lat), Number(long)], { icon }).addTo(mapRef.current!);
        }
      });

      if (authorPins.length > 0) {
        const validPins = authorPins.filter(pin => pin.location?.geoPoint);
        if (validPins.length > 0) {
          const bounds = L.latLngBounds(validPins.map(pin => [Number(pin.location.geoPoint!.lat), Number(pin.location.geoPoint!.long)]));
          mapRef.current.fitBounds(bounds);
        }
      }
    }
  };

  const updateMapPins = () => {
    if (!mapRef.current) return;

    // Clear existing markers
    mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        mapRef.current!.removeLayer(layer);
      }
    });

    // Add new markers
    const validPins = authorPins.filter(pin => pin.location?.geoPoint);
    validPins.forEach(pin => {
      const { lat, long } = pin.location.geoPoint!;
      const iconUrl = constructUrl(pin.pinIcon);
      const icon = L.icon({
        iconUrl: iconUrl,
        iconSize: [40, 40],
        iconAnchor: [20, 40]
      });
      L.marker([Number(lat), Number(long)], { icon }).addTo(mapRef.current!);
    });

    // Fit bounds if there are valid pins
    if (validPins.length > 0) {
      const bounds = L.latLngBounds(validPins.map(pin => [Number(pin.location.geoPoint!.lat), Number(pin.location.geoPoint!.long)]));
      mapRef.current.fitBounds(bounds);
    } else {
      // If no pins, set a default view
      mapRef.current.setView([0, 0], 2);
    }
  };

  const handleAuthorDetailsChange = async (details: Partial<typeof authorDetails>) => {
    setAuthorDetails(prevDetails => ({ ...prevDetails, ...details }));
    
    // Save changes to the database
    if (authorDetails.authorKey) {
      try {
        await AuthorManager.updateAuthor(authorDetails.authorKey, {
          name: details.authorName,
          bio: details.authorBio,
          website: details.authorWebsite,
          tags: { authorTags: details.authorTags || [] },
          image: details.authorImageUrl,
        });
        console.log('Author details updated successfully');
      } catch (error) {
        console.error('Error updating author details:', error);
        // Optionally, you can add error handling UI here
      }
    } else {
      console.error('Author key is missing');
    }
  };

  const setAuthorTags = (newTags: string[] | ((prevTags: string[]) => string[])) => {
    setAuthorDetails(prevDetails => ({
      ...prevDetails,
      authorTags: typeof newTags === 'function' ? newTags(prevDetails.authorTags) : newTags
    }));
  };

  const toggleEdit = async () => {
    if (isEditing) {
      // Save changes when exiting edit mode
      if (authorDetails.authorKey) {
        try {
          await AuthorManager.updateAuthor(authorDetails.authorKey, {
            name: authorDetails.authorName,
            bio: authorDetails.authorBio,
            website: authorDetails.authorWebsite,
            tags: { authorTags: authorDetails.authorTags || [] },
            image: authorDetails.authorImageUrl,
          });
          console.log('Author details saved successfully');
        } catch (error) {
          console.error('Error saving author details:', error);
          // Optionally, you can add error handling UI here
        }
      } else {
        console.error('Author key is missing');
      }
    }
    setIsEditing(!isEditing);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="author-dashboard">
      <Typography variant="h4" gutterBottom>
        Author Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} className="dashboard-section author-profile">
            <Typography variant="h6">Author Profile</Typography>
            {isEditing ? (
              <AuthorInformationFields
                initialAuthorName={authorDetails.authorName}
                initialAuthorBio={authorDetails.authorBio}
                initialAuthorWebsite={authorDetails.authorWebsite}
                initialAuthorTags={authorDetails.authorTags || []} // Provide default empty array
                initialAuthorImageUrl={authorDetails.authorImageUrl !== '/assets/icons/user.svg' ? constructUrl(authorDetails.authorImageUrl) : authorDetails.authorImageUrl}
                onAuthorDetailsChange={handleAuthorDetailsChange}
                setAuthorTags={setAuthorTags}
              />
            ) : (
              <Box>
                <img 
                  src={authorDetails.authorImageUrl !== '/assets/icons/user.svg' ? constructUrl(authorDetails.authorImageUrl) : authorDetails.authorImageUrl} 
                  alt={authorDetails.authorName} 
                  className="author-image" 
                />
                <Typography variant="subtitle1">{authorDetails.authorName}</Typography>
                <Typography variant="body2">{authorDetails.authorBio}</Typography>
                <Typography variant="body2">Website: {authorDetails.authorWebsite}</Typography>
                <Box mt={1}>
                  {authorDetails.authorTags.map((tag, index) => (
                    <span key={index} className="author-tag">{tag}</span>
                  ))}
                </Box>
              </Box>
            )}
            <Button onClick={toggleEdit} variant="contained" color="primary" className="edit-button">
              {isEditing ? 'Save' : 'Edit Profile'}
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} className="dashboard-section">
            <Typography variant="h6">My Recordings</Typography>
            {RecordingStore.recordings.size > 0 ? (
              <List className="recordings-list">
                {Array.from(RecordingStore.recordings.values()).map((recording) => (
                  <ListItem key={recording.key} className="recording-item">
                    <ListItemText 
                      disableTypography
                      primary={<Typography variant="subtitle1" className="recording-title">{recording.file?.title || 'Untitled Recording'}</Typography>}
                      secondary={
                        <div className="recording-details">
                          <div className="recording-detail-item">
                            <span className="recording-detail-label">Project:</span>
                            {recording.project && projectsMap.has(recording.project) ? projectsMap.get(recording.project)?.projectName : 'Unknown'}
                          </div>
                          <div className="recording-detail-item">
                            <span className="recording-detail-label">Description:</span>
                            {recording.file?.description || 'No description available'}
                          </div>
                          <div className="recording-detail-item">
                            <span className="recording-detail-label">Collection Count:</span>
                            {recording.collectionLog?.timesCollected || 0}
                          </div>
                        </div>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2">No recordings found.</Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} className="dashboard-section map-container">
            <Typography variant="h6">My Pins</Typography>
            {!isLoading && <Box id="authorMap" style={{ height: '400px', width: '100%' }}></Box>}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
});

export default AuthorDashboard;