import React, { useState } from 'react';
import FormLogin from './FormLogin';
import FormAuthorSignUp from './FormAuthorSignUp';
import "./AuthModal.css"

interface AuthModalProps {
  onClose: () => void;
  onAuthSuccess: () => void;
  authorName: string;
}

export const AuthModal: React.FC<AuthModalProps> = ({ onClose, onAuthSuccess, authorName }) => {
  const [isLogin, setIsLogin] = useState(false); // Changed to false to show signup first
  const [accountExists, setAccountExists] = useState(false);

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setAccountExists(false);
  };

  const handleAccountExists = () => {
    setAccountExists(true);
    setIsLogin(true);
  };

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal">
        <button className="close-button" onClick={onClose}>×</button>
        <p>Sign Up/Log in to Submit your details.</p>
        {isLogin ? (
          <FormLogin
            isArtistLogin={true}
            onLoginSuccess={onAuthSuccess}
            onToggleForm={toggleForm}
          />
        ) : (
          accountExists ? (
            <div className="account-exists-message">
              <p>An account with this email already exists.</p>
              <button onClick={() => setIsLogin(true)}>Go to Login</button>
            </div>
          ) : (
            <FormAuthorSignUp
              onAccountExists={handleAccountExists}
              onSignUpSuccess={onAuthSuccess}
              initialAuthorName={authorName}
              onToggleForm={toggleForm}
              isModal={true} // Add this prop
            />
          )
        )}
      </div>
    </div>
  );
};

export default AuthModal;