import React, { useState, useCallback, useEffect } from 'react';
import { TextField, Button, CircularProgress } from "@mui/material";
import "./EditRecording.css"

interface RecordingUploadFieldsProps {
    initialRecordingURL?: string;
    initialFileSize?: number;
    initialFileName?: string | null;
    initialTranscription?: string;  
    onRecordingDetailsChange: (details: { recordingURL: string; fileSize: number | undefined, file?: File, transcriptionText?: string, selectedButton: 'mp3' | 'url' }) => void;
    uploadPath: string;
    showTranscription?: boolean;
    selectedButton: 'mp3' | 'url';
}

const RecordingUploadFields: React.FC<RecordingUploadFieldsProps> = ({
    initialRecordingURL = '',
    initialFileSize,
    initialFileName = null,
    initialTranscription = '',  
    onRecordingDetailsChange,
    uploadPath,
    showTranscription = true,
    selectedButton: initialSelectedButton,
}) => {
    const [selectedButton, setSelectedButton] = useState<'mp3' | 'url'>(initialSelectedButton);
    const [recordingURL, setRecordingURL] = useState(initialRecordingURL);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFileName, setSelectedFileName] = useState<string | null>(initialFileName);
    const [fileSize, setFileSize] = useState<number | undefined>(initialFileSize);
    const [isUploading, setIsUploading] = useState(false);
    const [transcriptionText, setTranscriptionText] = useState<string>(initialTranscription);

    useEffect(() => {
        setTranscriptionText(initialTranscription);
    }, [initialTranscription]);

    const handleRecordingDetailsChange = useCallback((details: {
        recordingURL: string;
        fileSize: number | undefined,
        file?: File,
        transcriptionText?: string,
        selectedButton: 'mp3' | 'url'
    }) => {
        onRecordingDetailsChange({
            ...details,
            transcriptionText: transcriptionText // Always include the current transcription
        });
    }, [onRecordingDetailsChange, transcriptionText]);

    const handleRecordingURLChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const url = event.target.value;
        setRecordingURL(url);
        handleRecordingDetailsChange({
            recordingURL: url,
            fileSize: 0,
            selectedButton: 'url'
        });
    }, [handleRecordingDetailsChange]);

    const onMp3ButtonClick = useCallback(() => {
        setSelectedButton('mp3');
        setRecordingURL('');
        handleRecordingDetailsChange({
            recordingURL: '',
            fileSize: fileSize,
            selectedButton: 'mp3'
        });
    }, [fileSize, handleRecordingDetailsChange]);

    const onUrlButtonClick = useCallback(() => {
        setSelectedButton('url');
        setSelectedFile(null);
        setSelectedFileName(null);
        setFileSize(0);
        handleRecordingDetailsChange({
            recordingURL: recordingURL,
            fileSize: 0,
            selectedButton: 'url'
        });
    }, [recordingURL, handleRecordingDetailsChange]);

    const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            console.log("File selected:", file.name);
            setSelectedFile(file);
            setSelectedFileName(file.name);
            setFileSize(file.size);
            handleRecordingDetailsChange({
                recordingURL: '',
                file: file,
                fileSize: file.size,
                selectedButton: 'mp3'
            });
        } else {
            console.log("No file selected");
            setSelectedFile(null);
            setSelectedFileName(null);
            setFileSize(0);
            handleRecordingDetailsChange({
                recordingURL: recordingURL,
                fileSize: 0,
                selectedButton: 'url'
            });
        }
    }, [handleRecordingDetailsChange]);

    const handleTranscriptionTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setTranscriptionText(text);
        handleRecordingDetailsChange({
            recordingURL: recordingURL,
            fileSize: fileSize,
            transcriptionText: text,
            selectedButton: selectedButton
        });
    }, [recordingURL, fileSize, handleRecordingDetailsChange, selectedButton]);

    useEffect(() => {
        setSelectedButton(initialSelectedButton);
        if (initialSelectedButton === 'url' && initialRecordingURL) {
            setRecordingURL(initialRecordingURL);
        } else if (initialSelectedButton === 'mp3' && initialFileName) {
            setSelectedFileName(initialFileName);
            setFileSize(initialFileSize);
        }
    }, [initialSelectedButton, initialRecordingURL, initialFileName, initialFileSize]);

    return (
        <div className="recording-upload-container">
            <div className="fileurl1">
                <button 
                    className={`mp3-button ${selectedButton === 'mp3' ? 'selected' : ''}`} 
                    onClick={onMp3ButtonClick}
                >
                    <b className="file-upload">File Upload</b>
                </button>
                <button 
                    className={`url-button ${selectedButton === 'url' ? 'selected' : ''}`} 
                    onClick={onUrlButtonClick}
                >
                    <b className="url">URL</b>
                </button>
            </div>
            {selectedButton === 'mp3' && (
                <>
                    <div style={{ fontSize: 14, color: 'black' }}>
                        {selectedFileName ? `Selected File: ${selectedFileName}` : 'No file selected'}
                    </div>
                    <Button
                        color="primary"
                        size="medium"
                        variant="contained"
                        component="label"
                    >
                        {selectedFileName ? 'Change File' : 'Select File'}
                        <input
                            type="file"
                            hidden
                            onChange={handleFileChange}
                        />
                    </Button>
                </>
            )}
            {selectedButton === 'url' && (
                <>
                    <div className='url-instructions'>
                        Ensure the url ends with a file<br />
                        e.g. 'www.website.com/content/audiofile.mp3'
                    </div>
                    <TextField
                        label="Recording URL"
                        variant="filled"
                        fullWidth
                        value={recordingURL}
                        onChange={handleRecordingURLChange}
                    />
                </>
            )}
            {showTranscription && (
                <TextField
                    label="Transcription Text"
                    variant="filled"
                    fullWidth
                    multiline
                    rows={4}
                    value={transcriptionText}
                    onChange={handleTranscriptionTextChange}
                    placeholder="Paste your transcription text here"
                />
            )}
            <div className="buttons">
                {isUploading ? (
                    <Button
                        onClick={() => setIsUploading(false)} // Example button to stop upload
                        disabled={!isUploading}
                    >
                        {isUploading ? (
                            <CircularProgress size={24} style={{ color: 'blue' }} />
                        ) : (
                            <b>Cancel Upload</b>
                        )}
                    </Button>
                ) : (<div></div>)}
            </div>
        </div>
    );
};

export default RecordingUploadFields;