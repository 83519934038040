import { FunctionComponent, useState } from "react";
import FormLogin from "../components/FormLogin";
import FormAuthorSignUp from "../components/FormAuthorSignUp";
import "./LoginScreen.css";

const AuthorLoginScreen: FunctionComponent = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [accountExists, setAccountExists] = useState(false);

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setAccountExists(false);
  };

  const handleAccountExists = () => {
    setAccountExists(true);
    setIsLogin(true);
  };

  const handleSignUpSuccess = () => {
    setIsLogin(true);
  };

  return (
    <div className="loginscreen">
      <div className="login-content">
        <img
          className="logo-container-icon"
          alt="Overhear Logo"
          src="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
        />
        <div className="form-wrapper">
          {isLogin ? (
            <FormLogin 
              isArtistLogin={true} 
              onToggleForm={toggleForm}
            />
          ) : accountExists ? (
            <div className="account-exists-message">
              <p>An account with this email already exists.</p>
              <button onClick={() => setIsLogin(true)}>Go to Login</button>
            </div>
          ) : (
            <FormAuthorSignUp 
              onAccountExists={handleAccountExists}
              onSignUpSuccess={handleSignUpSuccess}
              initialAuthorName=""
              onToggleForm={toggleForm}
              isModal={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthorLoginScreen;