import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { ProjectManager, UserManager } from '../models';
import { projectStore } from '../stores/ProjectStore';
import { handleFileUpload, constructUrl } from "../utilities";
import { auth } from "../firebase";
import ProjectForm from './ProjectForm';
import { useProjectData } from "../hooks/useProjectData";
import { IProject } from "../types"; 
import allDataManager from "../models/AllDataManager";

type ProjectEditorProps = {
  onClose: () => void;
  project_id?: string;
  initialProjectData?: IProject;
  onSave?: (projectData: IProject) => Promise<void>;
};

const ProjectEditor: FunctionComponent<ProjectEditorProps> = ({
  onClose,
  project_id,
  initialProjectData,
  onSave,
}) => {
  const isEditMode = !!project_id;
  const [projectName, setProjectName] = useState(initialProjectData?.projectName || '');
  const [projectOwnerUserKey, setProjectOwnerUserKey] = useState(initialProjectData?.projectOwnerUserKey || '');
  const [projectIconUrl, setProjectIconUrl] = useState<string>('');
  const [isQREnabled, setIsQREnabled] = useState(initialProjectData?.isQREnabled || false);
  const [isSequentialEnabled, setIsSequentialEnabled] = useState(initialProjectData?.isSequentialEnabled || false);
  const [isDeliverPinsSequentially, setIsDeliverPinsSequentially] = useState(initialProjectData?.isDeliverPinsSequentially || false);
  const [isResponseEnabled, setIsResponseEnabled] = useState(initialProjectData?.isResponseEnabled || false);
  const [isTransitProjectEnabled, setIsTransitProjectEnabled] = useState(initialProjectData?.isTransitProjectEnabled || false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [tempIconUrl, setTempIconUrl] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const currentUserId = auth.currentUser?.uid;
  const { updateProject, loading, error } = useProjectData(project_id || '');

  useEffect(() => {
    if (isEditMode && initialProjectData?.icon) {
      // Construct the full URL for the project icon in edit mode
      setProjectIconUrl(constructUrl(initialProjectData.icon));
    } else {
      // Set default icon for create mode
      setProjectIconUrl(constructUrl('defaultIcons/pinkey.png'));
    }

    if (isEditMode && initialProjectData?.projectOwnerUserKey) {
      setProjectOwnerUserKey(initialProjectData.projectOwnerUserKey);
    } else if (currentUserId) {
      UserManager.getCurrentUser(currentUserId).then(user => {
        if (user && user.userKey) {
          setProjectOwnerUserKey(user.userKey);
        }
      });
    }
  }, [isEditMode, initialProjectData, currentUserId]);

  useEffect(() => {
    if (currentUserId) {
      const fetchUserKey = async () => {
        try {
          const currentUser = await UserManager.getCurrentUser(currentUserId);
          if (currentUser && currentUser.userKey) {
            setProjectOwnerUserKey(currentUser.userKey);
          }
        } catch (error) {
          console.error('Error fetching user key:', error);
        }
      };

      fetchUserKey();
    }
  }, [currentUserId]);

  useEffect(() => {
    if (isEditMode && project_id) {
      const fetchLatestProjectData = async () => {
        console.log('Fetching latest project data for project_id:', project_id);
        const latestData = await ProjectManager.getProject(project_id);
        console.log('Latest project data:', latestData);
        if (latestData) {
          setProjectName(latestData.projectName || '');
          setProjectIconUrl(latestData.icon ? constructUrl(latestData.icon) : '/default-project-icon.png');
          setIsQREnabled(latestData.isQREnabled || false);
          setIsSequentialEnabled(latestData.isSequentialEnabled || false);
          setIsDeliverPinsSequentially(latestData.isDeliverPinsSequentially || false);
          setIsResponseEnabled(latestData.isResponseEnabled || false);
          setIsTransitProjectEnabled(latestData.isTransitProjectEnabled || false);
        }
      };

      fetchLatestProjectData();
    }
  }, [isEditMode, project_id]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);
    if (file) {
      // Create a temporary URL for the selected file
      const tempUrl = URL.createObjectURL(file);
      setTempIconUrl(tempUrl);
      setProjectIconUrl(tempUrl); // Update projectIconUrl to show the preview
    }
  };

  const handleSave = async () => {
    if (!projectName || !projectOwnerUserKey) {
      console.error('Project name and User Key are required');
      return;
    }

    setIsUploading(true);

    let projectIconPath = isEditMode ? initialProjectData?.icon || '' : 'defaultIcons/pinkey.png';
    if (selectedFile) {
      try {
        const filePath = 'project-icons/';
        const fileName = `${Date.now()}${selectedFile.name}`;
        projectIconPath = await handleFileUpload(selectedFile, setProjectIconUrl, filePath, fileName);
      } catch (error) {
        console.error('Error uploading project icon:', error);
        setIsUploading(false);
        return;
      }
    }

    const projectData: IProject = {
      ...initialProjectData,
      projectName,
      icon: projectIconPath,
      isQREnabled,
      isSequentialEnabled,
      isDeliverPinsSequentially,
      isResponseEnabled,
      isTransitProjectEnabled,
      key: initialProjectData?.key || uuidv4(),
      project_id: project_id || uuidv4(),
      pins: initialProjectData?.pins || [],
      tags: initialProjectData?.tags || { subjectTag: [projectName], contentTag: '' },
      isAvailable: initialProjectData?.isAvailable || true,
      audioSize: initialProjectData?.audioSize || 0,
      projectOwnerUserKey,
    };

    if (isEditMode && project_id) {
      try {
        const updatedProject = await ProjectManager.updateProject(project_id, projectData);
        console.log('Project updated, response:', updatedProject);
        if (updatedProject && onSave) {
          onSave(updatedProject);
        }
        onClose();
      } catch (error) {
        console.error('Error updating project:', error);
      } finally {
        setIsUploading(false);
      }
    } else {
      const newProjectId = uuidv4();
      const newProjectData = {
        ...projectData,
        key: newProjectId,
        project_id: newProjectId,
        pins: [],
        tags: {
          subjectTag: [projectName],
          contentTag: '', 
        },
        isAvailable: true,
        audioSize: 0,
        projectOwnerUserKey,
      };

      try {
        await ProjectManager.createProject(newProjectId, newProjectData);
        projectStore.setSelectedProject(newProjectId);
        projectStore.addProject(newProjectData);
        if (onSave) {
          onSave(newProjectData);
        }
        onClose();
      } catch (error) {
        console.error('Error creating project:', error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleDelete = async () => {
    if (!isEditMode || !project_id) return;

    const confirmDelete = window.confirm('Are you sure you want to delete this project and all associated data?');
    if (confirmDelete) {
      try {
        await allDataManager.removeProjectAndAssociatedData(project_id);
        projectStore.deleteProject(project_id);
        onClose();
      } catch (error) {
        console.error('Error deleting project and associated data:', error);
      }
    }
  };

  // Clean up the temporary URL when the component unmounts
  useEffect(() => {
    return () => {
      if (tempIconUrl) {
        URL.revokeObjectURL(tempIconUrl);
      }
    };
  }, [tempIconUrl]);

  return (
    <div className="popup-portal">
      <h2 className="heading1">{isEditMode ? 'Edit Project' : 'Create Project'}</h2>
      <ProjectForm
        projectName={projectName}
        setProjectName={setProjectName}
        projectOwnerUserKey={projectOwnerUserKey}
        setProjectOwnerUserKey={setProjectOwnerUserKey}
        projectIconUrl={tempIconUrl || projectIconUrl}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        onFileChange={handleFileChange}
        isQREnabled={isQREnabled}
        setIsQREnabled={setIsQREnabled}
        isSequentialEnabled={isSequentialEnabled}
        setIsSequentialEnabled={setIsSequentialEnabled}
        isDeliverPinsSequentially={isDeliverPinsSequentially}
        setIsDeliverPinsSequentially={setIsDeliverPinsSequentially}
        isResponseEnabled={isResponseEnabled}
        setIsResponseEnabled={setIsResponseEnabled}
        isTransitProjectEnabled={isTransitProjectEnabled}
        setIsTransitProjectEnabled={setIsTransitProjectEnabled}
        isUploading={isUploading}
        onCancel={onClose}
        onSave={handleSave}
        onDelete={handleDelete}
        showDelete={isEditMode}
      />
    </div>
  );
};

export default ProjectEditor;
