import React, { useState, useCallback, useEffect } from "react";
import { IconButton, TextField, Button } from "@mui/material";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import UndoIcon from '@mui/icons-material/Undo';
import CloseIcon from '@mui/icons-material/Close';
import "./RecordingAccordion.css";
import "./AuthorInformationFields.css"
import { observer } from "mobx-react-lite";
import RecordingEditor from "./RecordingEditor";
import { Icon, PortalPopup } from "../components";
import { useRecordingData } from "../hooks/useRecordingData";
import { constructUrl } from "../utilities";
import { IRecordingWithAuthor, ITimingData } from "../types";
import RecordingStore from "../stores/RecordingStore";
import { RecordingManager } from "../models/RecordingManager";
import { runInAction } from "mobx";

export const RecordingAccordion: React.FC<{
  recordingKey: string;
  pinKey: string;
  pinData?: any;
  pinType?: string;
  className?: string;
  sourceView: 'PinList' | 'WanderView';
}> = observer(({ recordingKey, pinKey, pinData, pinType, className, sourceView }) => {
  const recordingData = RecordingStore.getRecording(recordingKey);
  const [isEditRecordingOpen, setEditRecordingOpen] = useState(false);
  const [editWord, setEditWord] = useState<{ word: string; index: number } | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentWord, setCurrentWord] = useState<string>("");
  const [previousTimingData, setPreviousTimingData] = useState<ITimingData[] | null>(null);

  useEffect(() => {
    if (!recordingData) {
      RecordingStore.fetchRecording(recordingKey);
    }
  }, [recordingKey]);

  const toggleAccordion = useCallback(() => {
    setIsOpen(prevOpen => !prevOpen);
  }, []);

  const openEditRecording = useCallback(() => {
    setEditRecordingOpen(true);
  }, []);

  const closeEditRecording = useCallback(() => {
    setEditRecordingOpen(false);
  }, []);

  const onSave = async (updatedRecording: IRecordingWithAuthor) => {
    try {
      await RecordingStore.updateRecording(updatedRecording.key, updatedRecording);
      // The reaction in useRecordingData will trigger a re-render
    } catch (error) {
      console.error('Error saving recording:', error);
    }
  };

  const getFilename = (path: string) => path.substring(path.lastIndexOf('/') + 1);

  const handleWordClick = (word: string, index: number) => {
    console.log(`Word clicked: ${word}, Index: ${index}`);
    setEditWord({ word, index });
    setCurrentWord(word);
  };

  const handleWordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentWord(event.target.value);
  };

  const addWord = async (index: number) => {
    if (recordingData && Array.isArray(recordingData.timingData)) {
      const updatedTimingData = [...recordingData.timingData];
      const originalTiming = updatedTimingData[index];

      // Calculate the duration of the original word
      const originalStartTime = originalTiming.startTime.seconds + originalTiming.startTime.nanos / 1e9;
      const originalEndTime = originalTiming.endTime.seconds + originalTiming.endTime.nanos / 1e9;
      const originalDuration = originalEndTime - originalStartTime;

      // Calculate the new duration for the original and new word
      const newDuration = originalDuration / 2;

      // Update the timing for the original word
      const newOriginalEndTime = originalStartTime + newDuration;
      updatedTimingData[index].endTime = {
        seconds: Math.floor(newOriginalEndTime),
        nanos: Math.floor((newOriginalEndTime % 1) * 1e9),
      };

      // Create the new word with the second half of the timing
      const newWord: ITimingData = {
        word: '[     ]', // Placeholder text
        startTime: updatedTimingData[index].endTime,
        endTime: {
          seconds: Math.floor(originalEndTime),
          nanos: Math.floor((originalEndTime % 1) * 1e9),
        },
      };

      // Insert the new word into the timing data
      updatedTimingData.splice(index + 1, 0, newWord);

      try {
        await RecordingManager.updateTimingData(recordingKey, updatedTimingData);
        console.log("Word added successfully.");
        // Update the local state to trigger a re-render
        runInAction(() => {
          recordingData.timingData = updatedTimingData;
        });
        // Set the editWord state to the newly added word
        setEditWord({ word: newWord.word, index: index + 1 });
        setCurrentWord(newWord.word);
      } catch (error) {
        console.error("Error adding word:", error);
      }
    }
  };

  const removeWord = async (index: number) => {
    if (recordingData && Array.isArray(recordingData.timingData)) {
      setPreviousTimingData([...recordingData.timingData]); // Save current state for undo
      const updatedTimingData = [...recordingData.timingData];

      // Calculate the duration of the word to be removed
      const removedWordTiming = updatedTimingData[index];
      const removedStartTime = removedWordTiming.startTime.seconds + removedWordTiming.startTime.nanos / 1e9;
      const removedEndTime = removedWordTiming.endTime.seconds + removedWordTiming.endTime.nanos / 1e9;
      const removedDuration = removedEndTime - removedStartTime;

      // Remove the word at the specified index
      updatedTimingData.splice(index, 1);

      // If there is a preceding word, add the removed duration to its end time
      if (index > 0) {
        const precedingWordTiming = updatedTimingData[index - 1];
        const precedingEndTime = precedingWordTiming.endTime.seconds + precedingWordTiming.endTime.nanos / 1e9;
        const newEndTime = precedingEndTime + removedDuration;

        updatedTimingData[index - 1].endTime = {
          seconds: Math.floor(newEndTime),
          nanos: Math.floor((newEndTime % 1) * 1e9),
        };
      }

      try {
        await RecordingManager.updateTimingData(recordingKey, updatedTimingData);
        RecordingStore.updateRecordingTimingData(recordingKey, updatedTimingData);
        console.log("Word removed successfully.");
        // Update the local state to trigger a re-render
        runInAction(() => {
          recordingData.timingData = updatedTimingData;
        });
      } catch (error) {
        console.error("Error removing word:", error);
      }
    }
  };

  const saveUpdatedWords = async () => {
    console.log("saveUpdatedWords function called");

    if (editWord && recordingData && Array.isArray(recordingData.timingData)) {
      console.log("Editing word:", editWord.word);

      try {
        runInAction(() => {
          recordingData.timingData[editWord.index].word = currentWord;
        });

        await RecordingManager.updateTimingData(recordingKey, recordingData.timingData);
        RecordingStore.updateRecordingTimingData(recordingKey, recordingData.timingData);
        console.log("Word text updated successfully.");
      } catch (error) {
        console.error("Error updating word text:", error);
      }

      setEditWord(null); // Clear the edit state
    }
  };

  const undoChanges = async () => {
    if (previousTimingData && recordingData) {
      try {
        await RecordingManager.updateTimingData(recordingKey, previousTimingData);
        RecordingStore.updateRecordingTimingData(recordingKey, previousTimingData);
      } catch (error) {
        console.error("Error undoing changes:", error);
      }
    }
  };

  const closeEditControls = () => {
    setEditWord(null);
  };

  if (!recordingData) return <div>Loading...</div>;

  const subjectTags = recordingData?.tags?.subjectTags?.filter((tag: string) => recordingData?.file?.title && tag !== recordingData?.file.title);
  const genreTags = recordingData?.tags?.genreTags;
  const authorTags = recordingData?.author?.tags?.authorTags?.filter((tag: string) => tag !== recordingData?.author?.name);
  const qrFilename = recordingData?.qrPath ? getFilename(recordingData.qrPath) : 'download';
  const downloadQR = async () => {
    if (recordingData?.qrPath) {
      try {
        const response = await fetch(constructUrl(recordingData.qrPath, true));
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = qrFilename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading QR code:', error);
      }
    }
  };

  return (
    <div className={`recordingaccordion ${className || ''}`}>
      <div className="recording-accordion-header">
        {sourceView !== 'WanderView' && (
          <>
            <div className="headingdata">{recordingData?.author?.name}</div>
            <div className="headingdata">{recordingData?.file?.title}</div>
          </>
        )}
        <div className="headingdata">{recordingData?.file?.recordingPath && getFilename(recordingData?.file.recordingPath)}</div>
        {sourceView !== 'WanderView' && (
          <>
            <div className="chevron-container" onClick={toggleAccordion}>
              <Icon
                icon="chevron"
                width={60}
                height={20}
                className={`chevron-stroke-icon ${isOpen ? 'chevron-rotated' : ''}`}
              />
            </div>
          </>
        )}
        <IconButton className="edit-container" color="primary" onClick={openEditRecording}>
          <EditSharpIcon style={{ fontSize: 40, color: 'black' }} />
        </IconButton>
      </div>

      {isOpen && (
        <div className="recording-information">
          <div className="details">
            <div className="photo-container">
              <img
                className="author-image-icon"
                alt={recordingData?.author?.name}
                src={recordingData?.author?.image 
                  ? constructUrl(recordingData.author.image) 
                  : '/assets/icons/user.svg'} // Use default image if no author image
              />
            </div>
            <div className="content-container">
              <div className="recordinglabel">Bio:</div>
              <div className="data"> {recordingData?.author?.bio}</div>
            </div>
            <div className="content-container">
              <div className="recordinglabel"> Description:</div>
              <div className="data"> {recordingData?.file?.description}</div>
            </div>
          </div>
          <div className="recording-next-row-details">
            <div className="content-container">
              <div className="data">{recordingData?.author?.website}</div>
            </div>

            {pinType === 'qrCode' && recordingData?.qrPath && ( // Conditional rendering based on pinType
              <div className="content-container">
                <div className="recordinglabel"> QR:</div>
                <div className="recordingdata">{recordingData?.whereQRFind}
                  <button onClick={downloadQR} className="download-qr-button">
                    Download QR
                  </button>
                </div>
              </div>
            )}
          </div>


          <div className="word-edit-section">
            {recordingData?.timingData && Array.isArray(recordingData.timingData) && (
              <div>
                {recordingData.timingData.map((item: ITimingData, index: number) => (
                  <div key={index} className="editable-word-container">
                    <span onClick={() => handleWordClick(item.word, index)} className="editable-word">
                      {item.word}
                    </span>
                    <div className="word-buttons">
                      <IconButton size="small" onClick={() => addWord(index)}>
                        <AddIcon fontSize="small" />
                      </IconButton>
                      <IconButton size="small" onClick={() => removeWord(index)}>
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {editWord && (
            <div className="edit-controls">
              <TextField
                value={currentWord}
                onChange={handleWordChange}
                label="Edit Word"
                variant="outlined"
              />
              <Button onClick={saveUpdatedWords} color="primary">
                Save
              </Button>
              <IconButton onClick={undoChanges} aria-label="Undo changes">
                <UndoIcon />
              </IconButton>
              <IconButton onClick={closeEditControls} aria-label="Close edit controls">
                <CloseIcon />
              </IconButton>
            </div>
          )}


          <div className="tags">
            <div className="tags-label">Author Tags:</div>
            {authorTags && authorTags.length > 0 && authorTags.map((tag: string, index: number) => (
              <div className="tag" key={index}>{tag}</div>
            ))}

            <div className="tags-label">Subject Tags:</div>
            {subjectTags && subjectTags.length > 0 && subjectTags.map((tag: string, index: number) => (
              <div className="tag" key={index}>{tag}</div>
            ))}

            <div className="tags-label">Genre Tags:</div>
            {genreTags && genreTags.length > 0 && genreTags.map((tag: string, index: number) => (
              <div className="tag" key={index}>{tag}</div>
            ))}
          </div>
        </div>
      )}


      {isEditRecordingOpen && recordingData && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeEditRecording}
        >
          <RecordingEditor
            onClose={closeEditRecording}
            recordingKey={recordingKey}
            recording={recordingData}
            pinKey={pinKey}
            pinType={pinData?.pinType}
            albumKey={pinData?.albumKey}
            sourceView={sourceView}
            onSave={onSave}
            config={{
              locationInfo: false,
              authorInfo: true,
              recordingInfo: true,
              uploadFields: true,
            }}
          />
        </PortalPopup>
      )}
    </div>
  );
});

export default RecordingAccordion;
