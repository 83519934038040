import { db } from '../firebase/firebase'; 
import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { User } from '../types';

export const UserManager = {
  /**
   * Fetches the current user's data from Firestore.
   * The userId should be obtained from the AuthenticationStore after successful authentication.
   * @param userId The UID of the user, as obtained from Firebase Authentication.
   * @returns A Promise that resolves to the User data or null if not found.
   */
  getCurrentUser: async (userId: string): Promise<User | null> => {
    try {
      const userDocRef = doc(db, 'Users', userId);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        console.log("No user found for ID:", userId);
        return null;
      }
  
      const userData = userDoc.data();
  
      return { userKey: userDoc.id, ...userData } as User;
    } catch (error) {
      console.error("Error fetching user:", error);
      return null;
    }
  },

  /**
   * Creates a new user document in Firestore.
   * @param userData The user data to be stored in Firestore.
   * @param username The username for the new user.
   * @returns A Promise that resolves to void if successful, or rejects with an error.
   */
  createUser: async (userData: Omit<User, 'userKey'> & { userKey: string }, username?: string): Promise<void> => {
    try {
      const { userKey, ...restUserData } = userData;
      const userDocRef = doc(db, 'Users', userKey);
      
      await setDoc(userDocRef, {
        ...restUserData,
        userKey,
        userProfile: {
          userName: username,
          bio: "",
          profileImage: "",
          restrictAutoAdd: false,
        },
        isAuthor: true,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      });

      console.log("User created successfully with ID:", userKey);
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  },

  /**
   * Fetches a user's data from Firestore by their userKey.
   * @param userKey The unique identifier for the user.
   * @returns A Promise that resolves to the User data or null if not found.
   */
  getUserByKey: async (userKey: string): Promise<User | null> => {
    try {
      const userDocRef = doc(db, 'Users', userKey);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        console.log("No user found for key:", userKey);
        return null;
      }

      const userData = userDoc.data();

      return { userKey: userDoc.id, ...userData } as User;
    } catch (error) {
      console.error("Error fetching user by key:", error);
      return null;
    }
  },
};

export default UserManager;