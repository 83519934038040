import { makeAutoObservable } from 'mobx';
import { auth } from '../firebase/firebase';
import { User, signInWithEmailAndPassword, signOut } from 'firebase/auth';

class AuthenticationStore {
  user: User | null = null;

  constructor() {
    makeAutoObservable(this);
    auth.onAuthStateChanged(this.setUser);
  }

  setUser = (user: User | null) => {
    this.user = user;
  }

  signIn = async (email: string, password: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential;
    } catch (error) {
      throw error;
    }
  }

  signOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      throw error;
    }
  }

  get isAuthenticated() {
    return !!this.user;
  }
}

const authenticationStore = new AuthenticationStore();
export default authenticationStore;